import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import SEO from "../../components/seo.js"
import Loader from '../../components/WidgetComponents/Loader'
import Layout from "../../components/layout"
import * as typeformEmbed from '@typeform/embed'
import {auth, firestore} from '../../utils/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import {getUserFirstName, getUserLastName} from '../../utils/general';

// import WaitlistAppBar from '../components/WidgetComponents/WaitlistAppBar.js'

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function Page(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [selected, setSelected] = useState(null)

  function requestAccess(userID) {

    let docRef = firestore
      .collection("users")
      .doc(userID);

    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          firestore
            .collection("users")
            .doc(userID)
            .update({requestedAccess: true, first: firstName, last: lastName, preferredEmail: email});
          window
            .analytics
            .track("Requested Beta Access", {
              // userId: user.uid,
              // "type": "organic",
              "first_name": firstName,
              "last_name": lastName,
              // "phone": "410-555-9412",
              // "username": "pgibbons",
              // "title": "Mr",
            });
          window
            .analytics
            .identify(userID, {
              requested_access: true,
              first_name: firstName,
              last_name: lastName,
              preferred_email: email
            });
          navigate('/requested')
        } else {
          console.log('No User')
          // navigate('/')
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  }

  function openForm() {
    typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
      // mode: 'drawer_left',
      autoOpen: true,
      // autoClose: 3,
      hideScrollbars: true,
      onSubmit: function() {
        console.log('Typeform successfully submitted')
      }
    })
  }

  function handleSignOut() {
    // firebase
    //   .auth()
    //   .signOut();
    navigate('/')
  }

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        // setInnerHeight(window.innerHeight)
        setIsLoaded(true)
      }
    }
  }, [isLoaded]);

  let buttonStyle = {
    margin: '10px',
    textTransform: "none",
    fontSize: '20px',
    width: '250px'
  }

  let selectedButtonStyle = {
    margin: '10px',
    textTransform: "none",
    fontSize: '20px',
    width: '250px',
    backgroundColor: '#d4ffe0',
    // color: '#00964b'
  }

  return (<Layout>
    <SEO title='Get Early Access'/>
    <Fade in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          margin: '0px',
          minHeight: '100vh',
          // width: '100vw',
          padding: '50px 10px'
        }}>
        <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
            backgroundColor: '',
            // height: '100vh',
            // width: '70vw',
            padding: '0px 10px'
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: '300',
              fontSize: '20px',
              // padding: '15px 20px 30px',
              padding: '0px',
              // marginBottom: '-40px',
              // position: 'relative',
              color: 'grey'
            }}>Get Early Access</Typography>
          <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
              backgroundColor: '',
              margin: 'auto',
              // marginTop: '-20px'
              paddingBottom: '28px'
            }}>
            <Typography style={{
                fontFamily: 'Work Sans',
                fontSize: '35px',
                fontWeight: 'bolder',
                textAlign: 'center',
                lineHeight: 1.1,
                padding: '20px 20px 18px'
                // height: '40px',
                // padding: '30px 50px 50px 50px'
              }}>{"And now the final details."}</Typography>
            <Grid container="container" direction='column' justify="center" alignItems="center" style={{
                // width: '300px'
              }}>
              <Grid style={{
                  maxWidth: '300px',
                  padding: '10px 0px 0px'
                }}>
                <TextField onChange={event => setFirstName(event.target.value)} variant='outlined' margin="normal" id="firstName" defaultValue={getUserFirstName(props.displayName)} label="First Name" type="text" fullWidth="fullWidth" style={{
                    margin: '15px 0px'
                  }}/>
                <TextField onChange={event => setLastName(event.target.value)} variant='outlined' margin="normal" id="lastName" defaultValue={getUserLastName(props.displayName)} label="Last Name" type="text" fullWidth="fullWidth" style={{
                    margin: '15px 0px'
                  }}/>
                <TextField onChange={event => setEmail(event.target.value)} variant='outlined' margin="normal" id="email" defaultValue={props.email} label="Preferred Email" type="email" fullWidth="fullWidth" style={{
                    margin: '15px 0px 5px'
                  }}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container="container" direction="row" wrap="wrap-reverse" justify="center" alignItems="center" style={{
            backgroundColor: '',
            // height: '40px'
          }}>
          <Button onClick={() => navigate('/join/website')} size='large' style={{
              margin: '8px 15px',
              color: 'grey'
            }}>Back</Button>
          {
            (selected !== 'a') && <Fade in="in">
                <Button color='secondary' variant='contained' size='large' onClick={() => requestAccess(props.userID, props.displayName, props.email, props.image)} style={{
                    // fontSize: '16px',
                    // textTransform: 'None',
                    margin: '8px 15px'
                  }}>Request Early Access</Button>
              </Fade>
          }
        </Grid>
      </Grid>
    </Fade>
  </Layout>)
}

export default function Home({
  location
}, props) {
  const [authUser, authLoading, authError] = useAuthState(auth);

  if (authLoading) {
    console.log('Authentication Loading')
    return (<Loader/>);
  }
  if (authError) {
    console.log('Authentication Error')
    navigate('/')
    return <Loader/>
  }
  if (authUser) {
    console.log('User Authenticated')
    window
      .analytics
      .identify(authUser.uid, {
        // name: authUser.displayName,
        // email: authUser.email
      });
    return (<Layout>
      <SEO title="My Dashboard"/>
      <Page userID={authUser.uid} displayName={authUser.displayName} email={authUser.email}/>
    </Layout>);
  }
  console.log('No User Authenticated')
  navigate('/')
  return <Loader/>
}

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
